import React from "react";
import { Link } from "gatsby";

interface ButtonLinkProps {
    title: string;
    to: string;
}

const ButtonLink = ({ title, to }: ButtonLinkProps) => {
    return (
        <div className="buttonLink__wrapper">
            <Link to={to} className="buttonLink__main">
                {title}
            </Link>
        </div>
    );
};

export default ButtonLink;
export { ButtonLinkProps };
