import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
    return (
        <div className="header__wrapper">
            <div className="container__main">
                <header className="header__main">
                    <Link to="/">
                        <StaticImage
                            className="header__logo"
                            alt="Logo Arek Wtykło"
                            src="../images/awtyklo-logo.svg"
                            placeholder="none"
                        />
                    </Link>
                    <nav className="header__nav">
                        <ul className="header__ul">
                            <li className="header__li">
                                <Link className="header__a" to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="header__li">
                                <Link className="header__a" to="/about">
                                    About
                                </Link>
                            </li>
                            <li className="header__li">
                                <Link className="header__a" to="/projects">
                                    Projects
                                </Link>
                            </li>
                            <li className="header__li">
                                <Link className="header__a" to="/contact">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </header>
            </div>
        </div>
    );
};

export default Header;
