import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

interface SeoProps {
    title?: string;
    description?: string;
    image?: string;
    article?: boolean;
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl
                defaultImage: image
            }
        }
    }
`;

const Seo = ({ title, description, image, article = true }: SeoProps) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);
    const { defaultTitle, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    return (
        <Helmet title={seo.title}>
            <meta charSet="utf-8" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {seo.url && <meta property="og:url" content={seo.url} />}

            {(article ? true : null) && <meta property="og:type" content="article" />}

            {seo.title && <meta property="og:title" content={seo.title} />}

            {seo.description && <meta property="og:description" content={seo.description} />}

            {seo.image && <meta property="og:image" content={seo.image} />}

            <meta name="twitter:card" content="summary_large_image" />

            {seo.title && <meta name="twitter:title" content={seo.title} />}

            {seo.description && <meta name="twitter:description" content={seo.description} />}

            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    );
};

export default Seo;
