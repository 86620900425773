import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

const Footer = () => {
    const now = new Date();

    return (
        <div className="footer__wrapper">
            <div className="container__main">
                <footer className="footer__main">
                    <div className="footer__copyright">Arek Wtykło &copy; {now.getFullYear()}</div>
                    <div onClick={() => scrollTo("#container")} className="footer__toTop">
                        scroll top
                        <span className="footer__toTopArrow" />
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Footer;
