import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "../scss/styles.scss";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div id="container" className="container__wrapper">
            <Header />
            <div className="container__main -grow">
                <div className="container__content">
                    <main className="content">{children}</main>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
export { LayoutProps };
